import React from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import { Switch, BrowserRouter, Route, Link } from "react-router-dom";
import { HashRouter } from "react-router-dom";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Login } from "./components/login/Login";
import { Menu } from "./components/master/Menu";
import Headers from "./components/master/Header";
import { Content } from "./components/master/Content";
import { ManageUsers } from "./components/users/ManageUsers";
import UserBoards from "./components/users/User-Boards";
import { AddPackage } from "./components/packages/AddPackage";
import { ManagePackage } from "./components/packages/ManagePackages";
import { Features } from "./components/packages/ManageFeatures";
import { ROUTE } from "./constants/constants";
import { AuthGuardedRoute } from "./guards/authGuard/AuthGuardedRoute";
import { ManageOrganisation } from "./components/users/ManageOrganisations";
import Footer from "./components/master/Footer";
import { ManageAdmin } from "./components/users/ManageAdmin";
import { OrgUsersList } from "./components/users/OrgUsersList";
import { Category } from "./components/category/Category";
import { UniversalTemplateAccess } from "./components/universalTemplateAccess/UniversalTemplateAccess";

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path={ROUTE.LOGIN} component={Login} />

          <AuthGuardedRoute path={[`${ROUTE.PACKAGE}/:id`, ROUTE.PACKAGE]}>
            <Layout>
              <AddPackage />
            </Layout>
          </AuthGuardedRoute>

          <AuthGuardedRoute path={[`${ROUTE.ORG_USERS_LIST}/:orgId`, ROUTE.ORG_USERS_LIST]}>
            <Layout>
              <OrgUsersList />
            </Layout>
          </AuthGuardedRoute>

          <AuthGuardedRoute path={ROUTE.CATEGORY}>
            <Layout>
              <Category />
            </Layout>
          </AuthGuardedRoute>

          <AuthGuardedRoute path={ROUTE.UNIVERSAL_TEMPLATE_ACCESS}>
            <Layout>
              <UniversalTemplateAccess />
            </Layout>
          </AuthGuardedRoute>
          

          <AuthGuardedRoute path={ROUTE.MANAGE_FEATURE}>
            <Layout>
              <Features />
            </Layout>
          </AuthGuardedRoute>

          <AuthGuardedRoute path={ROUTE.MANAGE_PACKAGE}>
            <Layout>
              <ManagePackage />
            </Layout>
          </AuthGuardedRoute>

          <AuthGuardedRoute path={ROUTE.ADMIN}>
            <Layout>
              <ManageAdmin />
            </Layout>
          </AuthGuardedRoute>

          {/* <AuthGuardedRoute path={[`${ROUTE.USER}/:id`, ROUTE.USER_DETAILS]}>
              <UserBoards />
            </AuthGuardedRoute> */}

          <AuthGuardedRoute path={ROUTE.USER}>
            <Layout>
              <ManageUsers />
            </Layout>
          </AuthGuardedRoute>

          <AuthGuardedRoute path={ROUTE.ADMIN}>
            <Layout>
              <ManageAdmin />
            </Layout>
          </AuthGuardedRoute>

          <AuthGuardedRoute path={ROUTE.ORGANISATION}>
            <Layout>
              <ManageOrganisation />
            </Layout>
          </AuthGuardedRoute>

          <AuthGuardedRoute path={ROUTE.DASHBOARD}>
            <Layout>
              <Content />
            </Layout>
          </AuthGuardedRoute>
          {/* <Route exact path={ROUTE.DASHBOARD} component={Content} /> */}
          {/* <Route exact path="/user-boards/:id" component={UserBoards} /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

const Layout = ({ children }) => {
  return (
    <>
      <Headers />
      <Menu />
      {children}
      <Footer />
    </>
  );
};
