import { URLS } from "../../constants/urls";
import { APIService } from "../api/api-service";
import { getCreateOrgModel, getUsers, getAdmins } from "./users.model";

export const userService = {
  getDashboardData: async () => {
    try {
      const response = await APIService.Instance.get(URLS.DASHBOARD_DATA);
      return response?.data;
    } catch (err) {
      console.log("error",err);
      return {};
    }
  },

  createUser: async (data) => {
    data.username = data.email;
    data.confirmPassword = data.password;
    try {
      const response = await APIService.Instance.post(URLS.CREATE_USER, data);
      return response?.data;
    } catch (err) {
      return err.response.data;
    }
  },
  deleteUser: async (id) => {
    try {
      const response = await APIService.Instance.post(URLS.DELETE_USER, { id });
      return response?.data;
    } catch (err) {
      return err.response.data;
    }
  },
  updateUser: async (data) => {
    try {
      const response = await APIService.Instance.put(URLS.UPDATE_USER, data);
      return response?.data;
    } catch (err) {
      return err.response.data;
    }
  },
  userDetails: async (data) => {
    try {
      const response = await APIService.Instance.post(URLS.USER_DETAILS, data);
      return response?.data;
    } catch (err) {
      return err.response.data
    }
  },
  activateDeactivateUser: async (data) => {
    try {

      const response = await APIService.Instance.put(URLS.ACTIVE_INACTIVE_USER, data);
      return response?.data;
    } catch (err) {
      return err.response.data
    }
  },

  createAdmin: async (data) => {
    try {
      data.email = data.user_name;
      console.log("data11111",data);
      const response = await APIService.Instance.post(URLS.ADMIN, data);
      return response?.data;
    } catch (err) {
      return err.response.data;
    }
  },
  getAllUsers: async () => {
    try {
      const response = await APIService.Instance.get(URLS.USER);
      return getUsers(response?.data);
    } catch (err) {
      console.log("error");
      return {};
    }
  },  
  getOrgAllUserList: async (orgId) => {
    try {
      const response = await APIService.Instance.get(`${URLS.ORG_USERS_LIST}/?org_id=${orgId}`);
      return getUsers(response?.data, orgId);
    } catch (err) {
      console.log("error");
      return {};
    }
  },
  addOrganisation: async (data) => {
    try {
      const response = await APIService.Instance.post(URLS.ORGANISATION,getCreateOrgModel(data));
      if (response.data.status) {
        return response?.data;
      }
      return [];
    } catch (err) {
      return err.response.data;
    }
  },
  changeOrganisationStatus: async (data) => {
    try {
      const response = await APIService.Instance.put(URLS.ORGANISATION_CHANGE_STATUS,data);
      if (response.data.status) {
        return response?.data;
      }
      return [];
    } catch (err) {
      return err.response.data;
    }
  },
  getOrganisations: async () => {
    try {
      const response = await APIService.Instance.get(URLS.ORGANISATION);
      if (response.data.status) {
        return response?.data;
      }
      return [];
    } catch (err) {
      console.log("getOrganisations ===>>",err);
      return err?.response?.data;
    }
  },
  getUserTypes: async () => {
    try {
      const response = await APIService.Instance.get(URLS.GET_USER_TYPE);
      if (response.data.status) {
        return response?.data;
      }
      return [];
    } catch (err) {
      return err.response.data;
    }
  },
  getAdmins: async () => {
    try {
      const response = await APIService.Instance.get(URLS.ADMIN);
      if (response.data.status) {
        return getAdmins(response?.data);
      }
      return [];
    } catch (err) {
      return err.response.data;
    }
  },
  updateUsersPackage: async (data) => {
    try {
      const response = await APIService.Instance.put(`${URLS.UPDATE_USERS_PACKAGE}/${data?._id}`,{ users: data.users });
      return response;
    } catch (err) {
      console.log("error");
      return {};
    }
  },
};
