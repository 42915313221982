import { URLS } from "../../constants/urls";
import { APIService } from "../api/api-service";
import { getCreateOrgModel, getCategory } from "./category.model";

export const categoryService = {

  getCategoryList: async () => {
    try {
      const response = await APIService.Instance.get(`${URLS.CATEGORY_LIST}`);
      return getCategory(response?.data);
    } catch (err) {
      console.log("error");
      return {};
    }
  },

  createCategory: async (data) => {
    try {
      const response = await APIService.Instance.post(URLS.CATEGORY_CREATE, data);
      return response?.data;
    } catch (err) {
      return err.response.data;
    }
  },
  updateCategory: async (data) => {
    try {
      const response = await APIService.Instance.post(URLS.CATEGORY_UPDATE, data);
      return response?.data;
    } catch (err) {
      return err.response.data;
    }
  },

  deleteCategory: async (id) => {
    try {
      const response = await APIService.Instance.post(URLS.CATEGORY_DELETE, { id });
      return response?.data;
    } catch (err) {
      return err.response.data;
    }
  },
  activateDeactivateCategory: async (data) => {
    try {
      const response = await APIService.Instance.put(URLS.ACTIVE_INACTIVE_USER, data);
      return response?.data;
    } catch (err) {
      return err.response.data
    }
  },
};
