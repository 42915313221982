import { AuthService } from "../auth/auth.service";

export const getCreateOrgModel = (data) => {
  return {
    name: data?.name,
    admin: data?.admin,
  };
};

export const getUsers = (data) => {
  data?.data.forEach((element, index) => {
    element.sno = index + 1;
    element.status = element?.isActive ? "Active" : "Inactive";
  });
  return data;
}

export const getAdmins = (data) => {
  data?.data.forEach((element, index) => {
    element.sno = index + 1;
    element.userTypeName = element.userType.name;
    element.status = element?.isActive ? "Active" : "Inactive";
  });
  return data;
}

