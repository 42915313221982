import { AuthService } from "../auth/auth.service";

export const getCreateOrgModel = (data) => {
  return {
    name: data?.name,
    admin: data?.admin,
  };
};

export const getCategory = (data)=>{
  data?.data.forEach((element,index) => {
    element.sno = index+1
  });
  return data;
}

