import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { universalTemplateAccessService } from "../../services/universalTemplateAccess/universalTemplateAccess.service";
import { Loader } from "../loader/Loader";
import { MultiSelect } from "primereact/multiselect";
import "./UniversalTemplateAccess.css";
import { ROUTE } from "../../constants/constants";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { toasterService, confirmBox } from "../../services/common/utilityService";


export const UniversalTemplateAccess = () => {
  const [isLoader, setLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);

  const toast = useRef(null);


  useEffect(() => {
    getFrontUserListList();
    getPreSelectedUserList();
  }, []);

  const getFrontUserListList = async () => {
    setLoader(true);
    try {
      const response = await universalTemplateAccessService.getFrontUserList();
      if (response.status) {
        console.log("response.data", response.data);
        setUserList(response.data);
      } else {
        console.error(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const getPreSelectedUserList = async () => {
    setLoader(true);
    try {
      const response = await universalTemplateAccessService.getPreSelectedUserList();
      if (response.status) {
        setSelectedUsers(response?.data?.members ? response.data.members : []);
      } else {
        console.error(response?.message);
      }
    } finally {
      setLoader(false);
    }
  }

  const submitUniersalTempleteForm = async () => {
    setLoader(true);
    try {
      const response = await universalTemplateAccessService.createUniversalTemplate({ "members": selectedUsers });
      if (response.status) {
        toasterService(toast, 'success', 'Success', response?.message, 3000);
        //setUserList(response.data);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);
        console.error(response?.message);
      }
    } finally {
      setLoader(false);
    }

  }

  const panelFooterTemplate = () => {

    console.log("selectedUsers", selectedUsers);

    const selectedItems = selectedUsers;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="py-2 px-3" key={length}>
        <b>{length}</b> User{length > 1 ? 's' : ''} selected.
      </div>
    );
  }


  const countryTemplate = (option) => {
    return (
      <div className="country-item" key={option._id}>
        <div>{option.user_name} ({option.displayName})</div>
      </div>
    );
  }


  return (
    <>
      {isLoader ? <Loader /> : null}
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="content-wrapper" style={{ height: "90vh", overflow: "scroll" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Universal Template Access</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href={ROUTE.DASHBOARD}>DashBoard</a></li>
                  <li className="breadcrumb-item active">Universal Template Access</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="card col-12" style={{ padding: '5px 5px' }}>
                <div className="card-body row m-0 p-2">
                  <div className="col-12 col-md-11 my-2">
                    <span className="field">
                      <div className="card flex justify-content-center">
                        <MultiSelect
                          value={selectedUsers}
                          onChange={(e) => setSelectedUsers(e.value)}
                          options={userList}
                          optionLabel="user_name"
                          display="chip"
                          placeholder="Select Users"
                          filter
                          //maxSelectedLabels={3}
                          className="w-full md:w-20rem"
                          panelFooterTemplate={panelFooterTemplate}
                          itemTemplate={countryTemplate}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="col-12 col-md-1 my-2">
                    <button className="btn btn-primary"
                      style={{ float: 'left', marginTop: "5px" }}
                      onClick={submitUniersalTempleteForm}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
