import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTE, USER_TYPE } from "../../constants/constants";
import { AuthService } from "../../services/auth/auth.service";

const MENU_CONFIG = [
  {
    path: ROUTE.DASHBOARD,
    icon: "fa fa-tachometer-alt",
    text: "DashBoard",
    meta: { allowedUserTypes: Object.values(USER_TYPE) },
    childRoutes: [],
  },
  {
    path: null,
    icon: "fa fa-university",
    text: "Organisation",
    meta: { allowedUserTypes: [USER_TYPE.SUPER_ADMIN] },
    childRoutes: [
      {
        path: ROUTE.ORGANISATION,
        icon: "",
        text: "Manage Organisations",
        meta: { allowedUserTypes: [USER_TYPE.SUPER_ADMIN] },
      },
    ],
  },
  {
    path: ROUTE.CATEGORY,
    icon: "fa fa-th-list",
    text: "Category",
    meta: { allowedUserTypes: [USER_TYPE.SUPER_ADMIN, USER_TYPE.ADMIN] },
    childRoutes: [],
  },
  {
    path: ROUTE.ADMIN,
    icon: "fa fa-users",
    text: "Manage Admin",
    meta: { allowedUserTypes: [USER_TYPE.SUPER_ADMIN] },
  },
  {
    path: ROUTE.UNIVERSAL_TEMPLATE_ACCESS,
    icon: "fa fa-paper-plane",
    text: "Universal Template",
    meta: { allowedUserTypes: [USER_TYPE.SUPER_ADMIN, USER_TYPE.ADMIN] },
    childRoutes: [],
  },
  // {
  //   path: null,
  //   icon: "fa fa-user",
  //   text: "Users",
  //   meta: { allowedUserTypes: ["superadmin", "admin"] },
  //   childRoutes: [
  //     {
  //       path: ROUTE.ADMIN,
  //       icon: "fa fa-users",
  //       text: "Manage Admins",
  //       meta: { allowedUserTypes: ["superadmin"] },
  //     },
  //     {
  //       path: ROUTE.USER,
  //       icon: "fa fa-users",
  //       text: "Manage Users",
  //       meta: { allowedUserTypes: ["superadmin", "admin"] },
  //     },
  //   ],
  // },
];

export const Menu = () => {
  const [user, setUser] = useState(() => AuthService.getUserDetails());
  const [userType, setUserType] = useState(() => AuthService.getUserType());
  const [menuDetails, setMenuDetails] = useState([]);

  useEffect(() => {
    if (user.userType) setMenuDetails(filterParentMenuByUserType());
  }, [userType, user.userType]);

  const filterParentMenuByUserType = () => {
    if (!userType) return;
    const filteredMenu = MENU_CONFIG.filter((menuDetails) =>
      menuDetails.meta.allowedUserTypes.includes(userType?.name)
    );
    return filteredMenu;
  };

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href={ROUTE.DASHBOARD} className="brand-link">
        <img
          src="dist/img/AdminLTELogo.png"
          alt="Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">Eflok</span>
      </a>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <Link to="" className="d-block">
              {user.displayName}
            </Link>
          </div>
        </div>
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {menuDetails?.length &&
              menuDetails.map((menu, idx) => {
                console.log("menu",menu);
                if (menu?.childRoutes?.length > 0) {
                  return (
                    <li key={idx} className="nav-item has-treeview parent">
                      <a className="nav-link">
                        <i className={`nav-icon ${menu?.icon}`} />
                        <p>
                          {menu.text}
                          <i className="fas fa-angle-left right" />
                          {/* <span className="badge badge-info right">1</span> */}
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        {menu?.childRoutes.map((child, cIdx) => {
                          if (
                            child?.meta?.allowedUserTypes.includes(
                              userType.name
                            )
                          )
                            return (
                              <li
                                key={`child-${idx}-${cIdx}`}
                                className="nav-item child"
                              >
                                <Link to={child.path} className="nav-link">
                                  <i className={` nav-icon ${child?.icon}`} />
                                  {child?.text}
                                </Link>
                              </li>
                            );
                        })}
                      </ul>
                    </li>
                  );
                }
                return (
                  <li key={idx} className="nav-item single">
                    <Link to={menu.path} className="nav-link">
                      <i className={`nav-icon ${menu?.icon}`} />
                      <p>{menu?.text}</p>
                    </Link>
                  </li>
                );
              })}

            <li className="nav-item has-treeview">
              <a className="nav-link" onClick={AuthService.logout}>
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>Logout</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};
