import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { categoryService } from "../../services/category/category.service";
import { Loader } from "../loader/Loader";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from "primereact/dropdown";
import "./category.css";
import { ORGANISATION_ACCESS, ROLE_LIST, ROUTE, STATUS, USER_TYPE } from "../../constants/constants";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { toasterService, confirmBox } from "../../services/common/utilityService";


export const Category = () => {
  const [isLoader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});

  const [category, setCategory] = useState([]);
  const [displayForm, setDisplayForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [rowData, setRowData] = useState({})

  const toast = useRef(null);

  useEffect(() => {
    getCategoryList();
  }, []);


  const handleValueChange = (event) => {
    const updatedValue = { ...formData };
    updatedValue[event?.target?.name] = event?.target?.value;
    setFormData(updatedValue);
  };

  const getCategoryList = async () => {
    setLoader(true);
    try {
      const response = await categoryService.getCategoryList();
      if (response.status) {
        setCategory(response.data);
      } else {
        console.error(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };


  const onAddCategoryClick = async () => {
    setLoader(true);
    try {

      formData.isActive = formData.isActive == 'Active' ? true : false;
      const response = await categoryService.createCategory(formData);
      if (response.status) {
        setFormData({});
        setDisplayForm(false)
        getCategoryList();
        toasterService(toast, 'success', 'Success', response?.message, 3000);
        //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);
        //        alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const onUpdateCategoryClick = async () => {
    setLoader(true);
    try {
      const response = await categoryService.updateCategory({ id: formData._id, name: formData.name, description: formData?.description ? formData?.description : 'N/A', isActive: formData.isActive == 'Active' ? true : false });
      if (response.status) {
        setFormData({});
        setDisplayForm(false)
        setRowData({})
        setIsEdit(false)
        getCategoryList();
        toasterService(toast, 'success', 'Success', response?.message, 3000);
        //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);
        //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const onDeleteCategoryClick = async (rowData) => {
    setLoader(true);
    try {
      const response = await categoryService.deleteCategory(rowData._id);
      if (response.status) {
        getCategoryList();
        toasterService(toast, 'success', 'Success', response?.message, 3000);
        //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);
        //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const onEditClickPopUp = (rowData) => {
    const obj = { ...rowData }
    obj.isActive = obj.isActive ? 'Active' : 'InActive';

    setRowData(obj);
    setIsEdit(true)
    setFormData(obj);
    setDisplayForm(true)
  }

  const activateDeactivateCategory = async (rowData) => {
    setLoader(true);
    try {
      const response = await categoryService.updateCategory({ id: rowData._id, name: rowData.name, description: rowData?.description ? rowData?.description : 'N/A', isActive: !rowData.isActive });
      if (response.status) {
        getCategoryList();
        toasterService(toast, 'success', 'Success', "Change category status successfully.", 3000);
        //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);
        //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  }


  const confirmDeleteDialogBox = (rowData) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteCategoryClick(rowData),
      reject: () => toasterService(toast, 'warn', 'Rejected', 'You have rejected category delete.', 3000)
    });
  };

  const confirmChangeStatusDialogBox = (rowData) => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => activateDeactivateCategory(rowData),
      reject: () => toasterService(toast, 'warn', 'Rejected', 'You have rejected change category status.', 3000)
    });
  };

  const tableData = {
    name: (rowData) => {
      return (
        <span className="text-capitalize">{`${rowData?.name || ""}`}</span>
      );
    },
    description: (rowData) => {
      return (
        <span className="text-capitalize">{`${rowData?.description || ""}`}</span>
      );
    },
    statusBadge: (rowData) => {
      return (
        <div className="flex align-items-center gap-2">
          {rowData.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
        </div>
      );
    },
    actions: (rowData) => {
      return (
        <>
          <i onClick={() => { onEditClickPopUp(rowData) }} className="fa fa-edit mr-2" />
          {/* <i onClick={() => { onDeleteCategoryClick(rowData) }} className="fa fa-trash mr-2" /> */}
          <i onClick={() => { confirmDeleteDialogBox(rowData) }} className="fa fa-trash mr-2" />
          {rowData.isActive ?
            <i onClick={() => { confirmChangeStatusDialogBox(rowData) }} className="fa fa-times mr-2" />
            :
            <i onClick={() => { confirmChangeStatusDialogBox(rowData) }} className="fa fa-check mr-2" />
          }
        </>
      );
    },
  };

  return (
    <>
      {isLoader ? <Loader /> : null}
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="content-wrapper" style={{ height: "90vh", overflow: "scroll" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Category List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href={ROUTE.DASHBOARD}>DashBoard</a></li>
                  <li className="breadcrumb-item active">Category List</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
              </div>
              <div className="col-sm-6 mt-2">
                <button className="btn btn-primary" onClick={() => setDisplayForm(true)} style={{ float: 'right' }}>
                  Add Category
                </button>
              </div>
            </div>
          </div>
        </section>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card mx-0 mt-3">
                  <DataTable
                    value={category}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    stripedRows
                    responsiveLayout="scroll"
                    loading={isLoader}
                  >
                    <Column
                      field="sno"
                      sortable
                      header="S.No."
                    ></Column>
                    <Column
                      field="name"
                      sortable
                      filter
                      body={tableData.name}
                      header="Category Name"
                    ></Column>
                    <Column
                      field="description"
                      sortable
                      filter
                      body={tableData.description}
                      header="Category Description"
                    ></Column>
                    <Column
                      header="Status"
                      field="isActive"
                      sortable
                      style={{ minWidth: '12rem' }}
                      body={tableData.statusBadge}
                      filter
                      filterPlaceholder="Search by country"
                    />
                    <Column
                      field=""
                      header="Actions"
                      body={tableData.actions}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Add Edit CATEGORY */}
      <Dialog header={isEdit ? 'Edit Category' : 'Add Category'}
        visible={displayForm}
        style={{ width: '55vw' }}
        onHide={() => {
          setDisplayForm(false);
          setRowData({});
          setIsEdit(false);
          setFormData({})
        }}>
        <div className="row m-0">
          <div className="col-12" style={{ padding: '5px 5px' }}>
            <div className="card mt-0 mb-3">
              <div className="card-body row m-0 p-2">
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <InputText
                      id="name"
                      name="name"
                      value={formData?.name || ""}
                      className="p-inputtext-sm col-12"
                      placeholder="Category Name"
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <Dropdown
                      name="isActive"
                      className="p-inputtext-sm col-12"
                      placeholder="Category Status"
                      value={formData?.isActive}
                      options={STATUS}
                      onChange={handleValueChange}
                      optionLabel="name"
                      optionValue="id"
                      filter
                      showClear
                      filterBy="name"
                      itemTemplate={(option) => (<div className="text-capitalize itemTemplate">{option?.name || ""}</div>)}
                      valueTemplate={(option) => { return option ? (<div className="text-capitalize valueTemplate">{option?.name || ""} </div>) : ("Category Status"); }}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <InputTextarea
                      id="description"
                      name="description"
                      placeholder="Category description..."
                      value={formData?.description || ""}
                      onChange={handleValueChange}
                      rows={5}
                      cols={100}
                    />
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 mt-2" >
          <button className="btn btn-secondary"
            onClick={() => {
              setDisplayForm(false);
              setRowData({});
              setIsEdit(false);
              setFormData({})
            }}
            style={{ float: 'right' }}>Cancel</button>
          <button className="btn btn-primary mr-2" onClick={isEdit ? onUpdateCategoryClick : onAddCategoryClick} style={{ float: 'right' }}>Submit</button>

        </div>
      </Dialog>
    </>
  );
};
