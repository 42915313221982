import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { userService } from "../../services/users/users.service";
import { Loader } from "../loader/Loader";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { Toast } from 'primereact/toast';
import "./user.css";
import { AuthService } from "../../services/auth/auth.service";
import { ORGANISATION_ACCESS, ROLE_LIST, ROUTE, USER_TYPE } from "../../constants/constants";
import { Dialog } from 'primereact/dialog';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toasterService, confirmBox } from "../../services/common/utilityService";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


export const OrgUsersList = () => {
  const [isLoader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [currentUserType, setCurrentUserType] = useState(() => AuthService.getUserType());
  const [user, setUser] = useState(() => AuthService.getUserDetails());

  const [orgs, setOrgs] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [displayForm, setDisplayForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [lazyParams, setLazyParams] = useState({ limit: 10, page: 1, });
  const [rowData, setRowData] = useState({})
  const [currentOrg, setCurrentOrg] = useState({})

  const { orgId } = useParams();
  const toast = useRef(null);

  useEffect(() => {
    getOrganisations();
    getUserTypes();
    getOrgAllUserList();
  }, []);

  const handleValueChange = (event) => {
    const updatedValue = { ...formData };
    updatedValue[event?.target?.name] = event?.target?.value;
    setFormData(updatedValue);
  };

  const getOrganisations = async () => {
    setLoader(true);
    const response = await userService.getOrganisations();
    if (response.status) {
      setOrgs(response.data);
      if (response.data.length > 0) {
        setCurrentOrg(response.data.find((org) => org._id == orgId));
      }
    } else {
      // console.error(response?.message);
    }
    setLoader(false);
  };

  const getUserTypes = async () => {
    setLoader(true);
    const response = await userService.getUserTypes();
    if (response.status) {
      setUserTypes(response?.data);
    } else {
      console.error(response?.message);
    }
    setLoader(false);
  };

  const getOrgAllUserList = async () => {
    setLoader(true);
    try {
      const response = await userService.getOrgAllUserList(orgId);
      if (response.status) {
        setUsers(response.data);
      } else {
        console.error(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };


  const onAddUserClick = async () => {
    setLoader(true);
    try {
      formData.email = formData.user_name;
      const response = await userService.createUser(formData);
      if (response.status) {
        setFormData({});
        setDisplayForm(false)
        getOrgAllUserList();
        toasterService(toast, 'success', 'Success', response?.message, 3000);  //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const onUpdateUserClick = async () => {
    setLoader(true);
    try {
      const reqObj = {
        id: formData._id,
        user_name: formData.user_name,
        email: formData.user_name,
        firstname: formData.firstname,
        lastname: formData.lastname,
        displayName: formData.firstname + " " + formData.lastname,
        organisation: formData.organisation,
        userType: formData.userType,
        role: formData.orgUserRole,
        org_access: formData.orguserAccess,
        password: formData.password
      }
      const response = await userService.updateUser(reqObj);
      if (response.status) {
        setFormData({});
        setDisplayForm(false)
        setRowData({})
        setIsEdit(false)
        getOrgAllUserList();
        toasterService(toast, 'success', 'Success', response?.message, 3000);  //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const onDeleteUserClick = async (rowData) => {
    setLoader(true);
    try {
      const response = await userService.deleteUser(rowData._id);
      if (response.status) {
        getOrgAllUserList();
        toasterService(toast, 'success', 'Success', response?.message, 3000);  //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const viewUserDetails = async (rowData) => {
    setShowUserDetails(true);
    setLoader(true);
    try {
      const response = await userService.userDetails({ id: rowData._id });
      if (response.status) {
        //console.log("response.data1111",response.data.organisation.find((data)=>data.organisationId._id ===orgId)?.org_access);
        setUserDetails(response.data);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  }

  const onEditClickPopUp = (rowData) => {
    let orgData = rowData?.organisation.find((org) => org.organisationId._id == orgId);
    const obj = { ...rowData }
    obj.userType = rowData?.userType?._id && userTypes?.find(item => item?._id == rowData?.userType?._id)?._id || null;
    obj.organisation = orgData.organisationId._id ? orgData.organisationId._id : '';
    obj.orgUserRole = orgData?.role ? orgData.role : '';
    obj.orguserAccess = orgData?.org_access ? orgData.org_access : '';

    setRowData(obj);
    setIsEdit(true)
    setFormData(obj);
    setDisplayForm(true)
  }

  const activateDeactivateUser = async (rowData) => {
    setLoader(true);
    try {
      const response = await userService.activateDeactivateUser({ id: rowData._id, isActive: !rowData.isActive });
      if (response.status) {
        getOrgAllUserList();
        toasterService(toast, 'success', 'Success', response?.message, 3000);  //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  }

  const confirmDeleteDialogBox = (rowData) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteUserClick(rowData),
      reject: () => toasterService(toast, 'warn', 'Rejected', 'You have rejected user delete.', 3000)
    });
  };

  const tableData = {
    name: (rowData) => {
      return (
        <span className="text-capitalize">{`${rowData?.firstname || ""} ${rowData?.lastname || ""
          }`}</span>
      );
    },
    userType: (rowData) => {
      return (
        <span className="text-capitalize">{`${rowData?.userType?.name || ""}`}</span>
      );
    },
    statusBadge: (rowData) => {
      return (
        <div className="flex align-items-center gap-2">
          {rowData.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
        </div>
      );
    },

    email: (rowData) => {
      return <>{rowData?.user_name || "-"}</>;
    },

    org: (rowData) => {
      return (<span className="text-capitalize">{currentOrg?.name || "-"}</span>);
    },
    actions: (rowData) => {
      return (
        <>
          <i onClick={() => { onEditClickPopUp(rowData) }} className="fa fa-edit mr-2" />
          <i onClick={() => { confirmDeleteDialogBox(rowData) }} className="fa fa-trash mr-2" />
          <i onClick={() => { viewUserDetails(rowData) }} className="fa fa-eye mr-2" />
          {rowData.isActive ?
            <i onClick={() => { activateDeactivateUser(rowData) }} className="fa fa-times mr-2" />
            :
            <i onClick={() => { activateDeactivateUser(rowData) }} className="fa fa-check mr-2" />
          }
        </>
      );
    },
  };

  return (
    <>
      {isLoader ? <Loader /> : null}
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="content-wrapper" style={{ height: "90vh", overflow: "scroll" }}>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Organisation Users List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href={ROUTE.DASHBOARD}>DashBoard</a></li>
                  <li className="breadcrumb-item"><a href={ROUTE.ORGANISATION}>{currentOrg?.name}</a></li>

                  <li className="breadcrumb-item active">Organisation Users List</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
              </div>
              <div className="col-sm-6 mt-2">
                <button className="btn btn-primary" onClick={() => setDisplayForm(true)} style={{ float: 'right' }}>
                  Add Users
                </button>
              </div>
            </div>
          </div>
        </section>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card mx-0 mt-3">
                  <DataTable
                    value={users}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    stripedRows
                    responsiveLayout="scroll"
                    loading={isLoader}
                  >
                    <Column
                      field="sno"
                      sortable
                      header="S.No."
                    ></Column>
                    <Column
                      field="firstname"
                      sortable
                      filter
                      body={tableData.name}
                      header="Name"
                    ></Column>
                    <Column
                      field="email"
                      sortable
                      body={tableData.email}
                      header="Email"
                    ></Column>
                    <Column
                      field="organisation"
                      sortable
                      sortField="organisation.name"
                      body={tableData.org}
                      header="Organisation"
                    ></Column>
                    <Column
                      field="userType"
                      sortable
                      sortField="userType.name"
                      filter
                      filterField="userType.name"
                      body={tableData.userType}
                      header="User Type"
                    ></Column>
                    <Column
                      header="Status"
                      field="status"
                      sortable
                      style={{ minWidth: '12rem' }}
                      body={tableData.statusBadge}
                      filter
                      filterPlaceholder="Search by status"
                    />
                    <Column
                      field=""
                      header="Actions"
                      body={tableData.actions}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Add Edit Users */}
      <Dialog header={isEdit ? 'Edit User' : 'Add User'} visible={displayForm} style={{ width: '55vw' }} onHide={() => { setDisplayForm(false); setRowData({}); setIsEdit(false); setFormData({}) }}>
        <div className="row m-0">
          <div className="col-12" style={{ padding: '5px 5px' }}>
            <div className="card mt-0 mb-3">
              <div className="card-body row m-0 p-2">

                <div className="col-4 col-md-6 my-2">
                  <span className="field">
                    <InputText
                      id="user_name"
                      name="user_name"
                      value={formData?.user_name || ""}
                      className="p-inputtext-sm col-12"
                      placeholder="Email"
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <InputText
                      id="firstname"
                      name="firstname"
                      value={formData?.firstname || ""}
                      className="p-inputtext-sm col-12"
                      placeholder="First Name"
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <InputText
                      id="lastname"
                      name="lastname"
                      value={formData?.lastname || ""}
                      className="p-inputtext-sm col-12"
                      placeholder="Last Name"
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <Dropdown
                      name="organisation"
                      className="p-inputtext-sm col-12"
                      placeholder="Select Organisation "
                      value={formData?.organisation}
                      options={orgs}
                      disabled={user?.userType?.name === USER_TYPE.ADMIN}
                      onChange={handleValueChange}
                      optionLabel="name"
                      optionValue="_id"
                      filter
                      showClear
                      filterBy="name"
                      itemTemplate={(option) => (
                        <div className="text-capitalize">
                          {option?.name || ""}
                        </div>
                      )}
                      valueTemplate={(option) => {
                        return option ? (
                          <div className="text-capitalize">
                            {option?.name || ""}
                          </div>
                        ) : (
                          "Select Organisation"
                        );
                      }}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <Dropdown
                      name="orgUserRole"
                      className="p-inputtext-sm col-12"
                      placeholder="Select Organisation User Role"
                      value={formData?.orgUserRole}
                      options={[{ "_id": ROLE_LIST.ADMIN, "name": ROLE_LIST.ADMIN }, { "_id": ROLE_LIST.USER, "name": ROLE_LIST.USER }]}
                      // disabled
                      onChange={handleValueChange}
                      optionLabel="name"
                      optionValue="_id"
                      filter
                      showClear
                      filterBy="name"
                      itemTemplate={(option) => (<div className="text-capitalize itemTemplate">{option?.name || ""}</div>)}
                      valueTemplate={(option) => { return option ? (<div className="text-capitalize valueTemplate">{option?.name || ""} </div>) : ("Select Organisation User Role"); }}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <Dropdown
                      name="orguserAccess"
                      className="p-inputtext-sm col-12"
                      placeholder="Select Organisation User Access"
                      value={formData?.orguserAccess}
                      options={[{ "_id": ORGANISATION_ACCESS.PARTIAL_ACCESS, "name": ORGANISATION_ACCESS.PARTIAL_ACCESS }, { "_id": ORGANISATION_ACCESS.FULL_ACCESS, "name": ORGANISATION_ACCESS.FULL_ACCESS }]}
                      //options={Object.values(ORGANISATION_ACCESS)}
                      // disabled
                      onChange={handleValueChange}
                      optionLabel="name"
                      optionValue="_id"
                      filter
                      showClear
                      filterBy="name"
                      itemTemplate={(option) => (<div className="text-capitalize itemTemplate">{option?.name || ""}</div>)}
                      valueTemplate={(option) => { return option ? (<div className="text-capitalize valueTemplate">{option?.name || ""} </div>) : ("Select Organisation User Access"); }}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <Dropdown
                      name="userType"
                      className="p-inputtext-sm col-12"
                      placeholder="Select UserType"
                      value={formData.userType}
                      options={userTypes}
                      // disabled
                      onChange={handleValueChange}
                      optionLabel="name"
                      optionValue="_id"
                      filter
                      showClear
                      filterBy="name"
                      itemTemplate={(option) => (
                        <div className="text-capitalize">
                          {option?.name || ""}
                        </div>
                      )}
                      valueTemplate={(option) => {
                        return option ? (
                          <div className="text-capitalize">
                            {option?.name || ""}
                          </div>
                        ) : (
                          "Select UserType"
                        );
                      }}
                    />
                  </span>
                </div>

                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <Password
                      id="password"
                      name="password"
                      value={formData?.password || ""}
                      className="p-inputtext-sm col-12 pl-0"
                      placeholder="Password"
                      toggleMask
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                {/* {JSON.stringify(formData)} */}

              </div>

            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 mt-2" >
          <button className="btn btn-primary" onClick={isEdit ? onUpdateUserClick : onAddUserClick} style={{ float: 'right' }}>
            Submit
          </button>
        </div>
      </Dialog>

      {/* user Details Popup */}
      <Dialog header="User Info" visible={showUserDetails} style={{ width: '50vw' }} onHide={() => { setShowUserDetails(false) }}>
        <div className="row m-0">
          <div className="col-12" style={{ padding: '5px 5px' }}>
            <div className="card mt-0 mb-3">
              <div className="card-body row m-0 p-2">
                <div className="col-12 col-md-12 my-2">
                  User Name: {userDetails?.user_name}
                </div>
                <div className="col-12 col-md-6 my-2">
                  First Name: {userDetails?.firstname}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Last Name: {userDetails?.lastname}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Last Name: {userDetails?.lastname}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Organisation: {currentOrg?.name || 'N/A'}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Organisation Role: {userDetails?.organisation?.find((data) => data.organisationId._id == orgId)?.role || 'N/A'}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Organisation Access: {userDetails?.organisation?.find((data) => data.organisationId._id == orgId)?.org_access || 'N/A'}
                </div>
                <div className="col-12 col-md-6 my-2">
                  User Type: {userTypes?.find(item => item?._id == userDetails?.userType?._id)?.name || 'N/A'}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Status: {userDetails?.isActive ? 'Active' : 'Inactive'}
                </div>

              </div>
            </div>
          </div>
        </div>
      </Dialog>

    </>
  );
};
