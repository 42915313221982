import React, { useRef } from "react";
import Headers from "../master/Header";
import { Menu } from "../master/Menu";
import Footer from "../master/Footer";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { userService } from "../../services/users/users.service";
import { Loader } from "../loader/Loader";
import { Link } from "react-router-dom";
import { ROUTE } from "../../constants/constants";
import { toasterService } from "../../services/common/utilityService";
import { Toast } from 'primereact/toast';

export const ManageOrganisation = () => {
  const [isLoader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});

  const [orgs, setOrgs] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    limit: 10,
    page: 1,
  });
  const toast = useRef(null);

  useEffect(() => {
    getOrganisations();
  }, []);

  const handleValueChange = (event) => {
    const updatedValue = { ...formData };
    updatedValue[event?.target?.name] = event?.target?.value;
    setFormData(updatedValue);
  };

  const addOrganisation = async () => {
    const response = await userService.addOrganisation(formData);
    if (response && response?.status) {
      toasterService(toast, 'success', 'Success', response?.message, 3000);  //alert(response?.message);
      setFormData({});
      getOrganisations();
    } else {
      toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
    }
  };

  const getOrganisations = async () => {
    setLoader(true);
    const response = await userService.getOrganisations();
    if (response && response?.status) {
      setLoader(false);
      response.data.forEach((element, index) => {
        element.sno = index + 1
      });
      setOrgs(response.data);
    } else {
      toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
    }
  };

  const activateDeactivateOrg = async (rowData) => {
    setLoader(true);
    try {
      console.log("rowData._id",rowData._id);
      const response = await userService.changeOrganisationStatus({ id: rowData._id, status: !rowData.isActive });
      if (response.status) {
        getOrganisations();
        toasterService(toast, 'success', 'Success', response?.message, 3000);  //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  }

  const tableData = {
    name: (rowData) => {
      return (
        <>
          {
            <Link to={`${ROUTE.ORG_USERS_LIST}/${rowData._id}`}>
              {rowData.name ? `${rowData.name.charAt(0).toUpperCase()}${rowData.name.slice(1)}` : ""}
            </Link>
          }
        </>
      );
    },
    statusBadge: (rowData) => {
      return (
        <div className="flex align-items-center gap-2">
          {rowData.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
        </div>
      );
    },
    serialNo: (rowData, props) => {
      return <>{rowData && props.rowIndex + 1}</>;
    },
    createdBy: (rowData) => {
      return <>{rowData?.createdBy?.displayName}</>;
    },
    admin: (rowData) => {
      return <>{rowData?.admin?.displayName || "-"}</>;
    },
    actions: (rowData) => {
      return (
        <>
          {rowData.isActive ?
            <i onClick={() => { activateDeactivateOrg(rowData) }} className="fa fa-times mr-2" />
            :
            <i onClick={() => { activateDeactivateOrg(rowData) }} className="fa fa-check mr-2" />
          }
        </>
      );
    },
  };

  const currentOrgDetails = (rowData) => {
    console.log("Click", rowData);
  }
  return (
    <>
      {isLoader ? <Loader /> : null}
      <Toast ref={toast} />
      <div
        className="content-wrapper"
        style={{ height: "90vh", overflow: "scroll" }}
      >
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Organisation List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href={ROUTE.DASHBOARD}>DashBoard</a></li>
                  <li className="breadcrumb-item active">Organisation List</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card mx-3 mt-3">
                  <div className="card-body d-flex ">
                    <input
                      name="name"
                      type="text"
                      value={formData?.name || ""}
                      className="form-control"
                      placeholder="Organisation"
                      onChange={handleValueChange}
                    />
                    <button
                      className="btn btn-primary ml-lg-3"
                      onClick={addOrganisation}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* /.row */}

            <div className="row">
              <div className="col-12">
                <div className="card mx-3 mt-3">
                  <DataTable
                    value={orgs}
                    paginator
                    rows={10}
                    responsiveLayout="scroll"
                    loading={isLoader}
                  >
                    <Column field="sno" header="S. No."></Column>
                    <Column
                      field="name"
                      body={tableData.name}
                      filter={true}
                      sortable
                      header="Name"
                    ></Column>
                    <Column
                      field="admin"
                      body={tableData.admin}
                      header="Admin"
                    ></Column>
                    <Column
                      field="isActive"
                      body={tableData.statusBadge}
                      header="Active"
                    ></Column>
                    <Column
                      field=""
                      header="Actions"
                      body={tableData.actions}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </div>
      </div>
    </>
  );
};
