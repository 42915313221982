import { useEffect, useState } from "react";
import React from "react";
import Headers from "../master/Header";
import { Menu } from "../master/Menu";
import Footer from "../master/Footer";

import { PackageService } from "../../services/package/package.service";
import { useParams } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Loader } from "../loader/Loader";

export const AddPackage = () => {
  const [formData, setFormData] = useState("");
  const [features, setFeatures] = useState([]);
  const [packageId, setPackageId] = useState(""); // For Edit Stage
  const [packageData, setPackageData] = useState({}); // For Edit Stage Processing
  const [isLoader, setIsLoader] = useState(false);

  const { id } = useParams();
  if (id) {
    if (id !== packageId) {
      setPackageId(id);
    }
  }

  // useEffect(() => {
  //   getAllFeatures();
  // }, []);

  useEffect(() => {
    setFeaturesDefaultInput();
  }, [features]);

  useEffect(() => {
    if (packageId) {
      getPackageById(packageId);
    }
  }, [packageId]);

  useEffect(() => {
    setFeaturesDefaultInput();
  }, [packageId, packageData]);

  // for table
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    limit: 10,
    page: 1,
  });

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      getAllFeatures();
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    setLazyParams({ limit: event.rows, page: event.page + 1 });
  };
  // for table

  const getPackageById = async (packageId) => {
    const response = await PackageService.getPackageById(packageId);
    if (response.status) {
      setPackageData(response?.data?.[0] || {});
      setFormData(response?.data?.[0] || formData);
    }
  };

  const setFeaturesDefaultInput = () => {
    let featuresInput = {};

    featuresInput = features.reduce((featureObj, feature) => {
      featureObj[feature?._id] = {
        _id: feature?._id,
        isAllowed: false,
        value: "",
        name: feature?.name,
      };
      return featureObj;
    }, {});

    if (packageId && packageData) {
      const filledValues = packageData?.features?.reduce(
        (featureObj, feature) => {
          featureObj[feature?._id] = {
            _id: feature?._id,
            isAllowed: feature?.isAllowed,
            value: feature?.value,
            name: feature?.name,
          };
          return featureObj;
        },
        {}
      );

      featuresInput = {
        ...featuresInput,
        ...filledValues,
      };
    }

    setFormData((prevState) => ({ ...prevState, features: featuresInput }));
  };

  const handleValueChange = (event) => {
    const updatedValue = { ...formData };
    updatedValue[event?.target?.name] = event?.target?.value;
    setFormData(updatedValue);
  };

  const handleFeatureValueChange = (id, name, value) => {
    const formDataValue = { ...formData };
    let features = { ...formDataValue?.features };
    features[id][name] = value;
    formDataValue.features = { ...features };
    setFormData((prev) => ({ ...prev, ...formDataValue }));
    console.log(formDataValue);
  };

  const addPackage = async () => {
    setIsLoader(true);
    const response = await PackageService.addPackage(formData);
    setIsLoader(false);
    if (response.status) {
      setFormData({
        duration: "",
        name: "",
        price: "",
        isActive: false,
      });
    }
  };

  const updatePackage = async () => {
    const response = await PackageService.updatePackage(formData);
    if (response.status) {
      console.log(response);
    }
  };

  const getAllFeatures = async () => {
    const response = await PackageService.getAllFeatures(lazyParams);
    if (response.status) {
      setLoading(false);
      setFeatures(response?.data?.features);
      setTotalRecords(response?.data?.totalFeatures);
    }
  };

  const tableData = {
    serialNo: (rowData, props) => {
      return (
        <>{lazyParams.limit * (lazyParams.page - 1) + props.rowIndex + 1}</>
      );
    },
    AllowCheckbox: (rowData) => {
      return (
        <>
          {
            <input
              style={{ marginLeft: "1em", height: "15px" }}
              onChange={(e) => {
                handleFeatureValueChange(
                  rowData?._id,
                  "isAllowed",
                  e.target.checked
                );
              }}
              name={`${rowData?._id}_active`}
              type="checkbox"
              class="form-check-input"
              checked={
                formData.features?.[rowData?._id]?.isAllowed ? true : false
              }
            />
          }
        </>
      );
    },
    valueInputField: (rowData) => {
      return (
        <>
          {
            <input
              onChange={(e) => {
                handleFeatureValueChange(rowData?._id, "value", e.target.value);
              }}
              name={`${rowData?._id}_value`}
              type="text"
              class="form-control"
              placeholder="Value"
              value={formData.features?.[rowData?._id]?.value}
            />
          }
        </>
      );
    },
  };

  console.log(formData);
  return (
    <>
      {isLoader ? <Loader /> : null}
      <div>
        <Headers />
        <Menu />
        <div className="content-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="card">
                {/* <div className="card-header">
                    <h3 className="card-title">Packages Details</h3>
                  </div> */}
                <div className="card-body">
                  <section class="content">
                    <div class="container-fluid">
                      <div class="row">
                        {/* <!-- left column --> */}
                        <div class="col-md-12">
                          {/* <!-- general form elements --> */}
                          <div class="card card-primary">
                            <div class="card-header">
                              <h3 class="card-title">Package Details</h3>
                            </div>

                            {/* <form role="form"> */}
                            <div class="card-body">
                              <div className="row">
                                <div class="form-group col-sm-4">
                                  <label for="Package Name">Package Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    onChange={handleValueChange}
                                    class="form-control"
                                    placeholder="Enter name"
                                    value={formData?.name}
                                  />
                                </div>
                                <div class="form-group col-sm-4">
                                  <label for="Price">Package Price (INR)</label>
                                  <input
                                    name="price"
                                    type="number"
                                    onChange={handleValueChange}
                                    class="form-control"
                                    placeholder="Enter price"
                                    value={formData?.price}
                                  />
                                </div>
                                <div class="form-group col-sm-4">
                                  <label for="exampleInputEmail1">
                                    Package Duration (Days)
                                  </label>
                                  <input
                                    name="duration"
                                    type="number"
                                    onChange={handleValueChange}
                                    class="form-control"
                                    id="duration"
                                    placeholder="Enter duration in days"
                                    value={formData?.duration}
                                  />
                                </div>
                              </div>

                              <div className="card-body">
                                <label>Allowed Features</label>
                                {/* <table
                                  id="example2"
                                  className="table table-bordered table-hover"
                                >
                                  <thead>
                                    <tr>
                                      <th>S.No</th>
                                      <th>Name</th>
                                      <th>Allow</th>
                                      <th>Value</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {features.map((feature, index) => (
                                      <>
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{feature?.name}</td>
                                          <td>
                                            <input
                                              style={{ marginLeft: '1em', height: '15px' }}
                                              onChange={(e) => {
                                                handleFeatureValueChange(feature?._id, 'isAllowed', e.target.checked)
                                              }}
                                              name={`${feature?._id}_active`}
                                              type="checkbox"
                                              class="form-check-input"
                                              checked={formData.features?.[feature?._id]?.isAllowed?true:false}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              onChange={(e) => {
                                                handleFeatureValueChange(feature?._id, 'value', e.target.value)
                                              }}
                                              name={`${feature?._id}_value`}
                                              type="text"
                                              class="form-control"
                                              placeholder="Value"
                                              value={formData.features?.[feature?._id]?.value}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </table> */}

                                <DataTable
                                  value={features}
                                  responsiveLayout="scroll"
                                  lazy
                                  paginator
                                  first={lazyParams.first}
                                  rows={lazyParams.limit}
                                  totalRecords={totalRecords}
                                  onPage={onPage}
                                  loading={loading}
                                >
                                  <Column
                                    header="S.No."
                                    body={tableData.serialNo}
                                  ></Column>
                                  <Column field="name" header="Name"></Column>
                                  <Column
                                    field="isActive"
                                    header="Allow"
                                    body={tableData.AllowCheckbox}
                                  ></Column>
                                  <Column
                                    field="value"
                                    header="Value"
                                    body={tableData.valueInputField}
                                  ></Column>
                                </DataTable>
                              </div>

                              <div class="form-group">
                                <label
                                  style={{ display: "inline-block" }}
                                  for="exampleCheck1"
                                >
                                  Active
                                </label>
                                <input
                                  style={{ marginLeft: "1em", height: "15px" }}
                                  onChange={handleValueChange}
                                  name="isActive"
                                  type="checkbox"
                                  class="form-check-input"
                                  checked={formData?.isActive}
                                />
                              </div>
                            </div>
                            {/* <!-- /.card-body --> */}
                            <div class="card-footer">
                              {packageId.length <= 0 ? (
                                <button
                                  onClick={addPackage}
                                  class="btn btn-primary"
                                >
                                  Add Package
                                </button>
                              ) : (
                                <button
                                  onClick={updatePackage}
                                  class="btn btn-primary"
                                >
                                  Update Package
                                </button>
                              )}
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
