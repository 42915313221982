import { useEffect, useState } from "react";
import React from "react";

import Headers from "../master/Header";
import { Menu } from "../master/Menu";
import Footer from "../master/Footer";
import { PackageService } from "../../services/package/package.service";
import { ROUTE } from "../../constants/constants";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export const ManagePackage = () => {
  const [packages, setPackages] = useState([]);

  // for table
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({limit: 10,page: 1});

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      getPackages(lazyParams);
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    setLazyParams({ limit: event.rows, page: event.page + 1 });
  };
  // for table

  const getPackages = async (event) => {
    const response = await PackageService.getPackages(event);
    console.log("Packages:");
    console.log(response);
    if (response.status) {
      setLoading(false);
      setPackages(response?.data?.packages);
      setTotalRecords(response?.data?.totalPackages);
    }
  };

  const tableData = {
    status: (rowData) => {
      return <>{rowData.isActive ? "Active" : "Inactive"}</>;
    },
    viewInfo: (rowData) => {
      return (
        <>
          {
            <Link to={`${ROUTE.PACKAGE}/${rowData._id}`}>
              <i className="fa fa-edit " />
            </Link>
          }
        </>
      );
    },
    serialNo: (rowData, props) => {
      return (
        <>{lazyParams.limit * (lazyParams.page - 1) + props.rowIndex + 1}</>
      );
    },
  };

  return (
    <>
      <div>
        <Headers />
        <Menu />
        <div className="content-wrapper">
          {/* meterial ui Modals  */}
          <div></div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Packages Details</h3>
                </div>
                <div className="card-body">
                  <DataTable
                    value={packages}
                    responsiveLayout="scroll"
                    lazy
                    paginator
                    first={lazyParams.first}
                    rows={lazyParams.limit}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    loading={loading}
                  >
                    <Column header="S.No" body={tableData.serialNo}></Column>
                    <Column field="name" header="Name"></Column>
                    <Column field="price" header="Price"></Column>
                    <Column field="duration" header="Duration"></Column>
                    <Column
                      field="Status"
                      body={tableData.status}
                      header="Status"
                    ></Column>
                    <Column field="view" body={tableData.viewInfo}></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
