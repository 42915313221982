const TOKEN = "token";
const USER_DETAILS = "user";

const USER_TYPE = {
  SUPER_ADMIN: "superadmin",
  ADMIN: "admin",
  NORMAL: "normal",
};

const ROUTE = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  CATEGORY: "/category",
  MANAGE_FEATURE: "/features",
  PACKAGE: "/package",
  MANAGE_PACKAGE: "/packages",
  USER: "/users",
  ADMIN: "/user/admins",
  USER_DETAILS: "/user",
  ORGANISATION: "/organisation",
  ORG_USERS_LIST: "/orguserslist",
  UNIVERSAL_TEMPLATE_ACCESS: "/universal-template-access"
};

const ROLE_LIST = {
  ADMIN: "admin",
  USER: "user",
};

const ORGANISATION_ACCESS = {
  PARTIAL_ACCESS: "partial-access",
  FULL_ACCESS: "full-access",
};

const STATUS = [{ "id": "Active", "name": "Active" }, { "id": "InActive", "name": "InActive" }];

export { TOKEN, USER_DETAILS, USER_TYPE, ROUTE, ROLE_LIST, ORGANISATION_ACCESS, STATUS };
