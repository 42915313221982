import React, { useEffect, useState, useRef } from "react";
import { AuthService } from "../../services/auth/auth.service";
import { ROLE, ROUTE, TOKEN, USER_DETAILS } from "../../constants/constants";
import { APIService } from "../../services/api/api-service";
import { Redirect } from "react-router-dom";
import { toasterService } from "../../services/common/utilityService";
import { Toast } from 'primereact/toast';

export const Login = () => {
  const [formData, setFormData] = useState({});
  const [redirect, setRedirect] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    if (localStorage.getItem(TOKEN) !== null) {
      setRedirect(true);
    }
  }, []);

  const handleValueChange = (event) => {
    const updatedValue = { ...formData };
    updatedValue[event?.target?.name] = event?.target?.value;
    setFormData(updatedValue);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      login()
    }
  }

  const login = async () => {
    const response = await AuthService.login(formData);
    console.log("response", response);
    if (response?.status) {
      localStorage.setItem(TOKEN, response?.token);
      localStorage.setItem(USER_DETAILS, JSON.stringify(response?.userDetail)); //response.data
      setRedirect(true);
    } else {
      toasterService(toast, 'error', 'Error','Invalid Admin Credentials..', 3000);   //alert("Invalid Admin Credentials..");
    }
  };

  if (redirect === true) {
    window.location.href = ROUTE.DASHBOARD; // Hotfix for first time reload
    return;
    // return <Redirect to={ROUTE.DASHBOARD} />;
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <a href="javascript:void();">eFlok Admin</a>
          </div>

          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <div className="input-group mb-3">
                <input
                  name="username" //"email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={handleValueChange}
                />
                {/* {errors.email && <span>This field is required</span>} */}
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  name="password"
                  required
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={handleValueChange}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button onClick={login} className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
                <div className="col-4"></div>
                {/* <div className="col-4">
                    <button type="button" className="btn btn-primary btn-block">
                      Register
                    </button>
                  </div> */}
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
