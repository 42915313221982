import { TOKEN, USER_DETAILS } from "../../constants/constants";
import { URLS } from "../../constants/urls";
import { APIService } from "../api/api-service";
import { getLoginModel } from "./auth.model";

export const AuthService = {
  login: async (data) => {
    try {
      console.log(data);
      const response = await APIService.Instance.post(
        URLS.LOGIN,
        getLoginModel(data)
      );
      return response?.data;
    } catch (err) {
      console.log("error:".err);
      return {};
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN);
    window.location.reload();
  },
  getUserDetails: () => {
    const stringifiedResponse = localStorage.getItem(USER_DETAILS);
    if (!stringifiedResponse) return null;
    return JSON.parse(stringifiedResponse);
  },
  getUserType: () => {
    const user = AuthService.getUserDetails();
    console.log("user",user);
    if (!user) return null;
    return user?.userType;
  },
  getFullName: () => {
    const user = AuthService.getUserDetails();
    if (!user) return null;
    return `${user?.firstname} ${user?.lastname || ""}`;
  },
  getEmail: () => {
    const user = AuthService.getUserDetails();
    if (!user) return null;
    return user?.email;
  },
  getOrganisation: () => {
    const user = AuthService.getUserDetails();
    if (!user) return null;
    return user?.organisation;
  },
};
