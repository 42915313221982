export const getAddFeatureModel = (data) => {
    return {
        name: data?.name,
    };
}

export const getAddPackageModel = (data) => {
    return {
        name: data?.name  ,
        duration: data?.duration,
        price: data?.price,
        currency: 'INR',
        isActive: data?.isActive === "on" || data?.isActive === true ? true : false,
        features: [...Object.values(data?.features)]
    }
}
