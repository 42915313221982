import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { userService } from "../../services/users/users.service";
import { Loader } from "../loader/Loader";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import "./user.css";
import { Toast } from 'primereact/toast';
import { toasterService, confirmBox } from "../../services/common/utilityService";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export const ManageAdmin = () => {
  const [isLoader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});

  const [orgs, setOrgs] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [lazyParams, setLazyParams] = useState({ limit: 10, page: 1 });
  const toast = useRef(null);

  useEffect(() => {
    getOrganisations();
    getUserTypes();
    getAdmins();
  }, []);

  useEffect(() => {
    const adminUserTypeId =
      userTypes?.filter((type) => type?.name === "admin")?.[0]?._id || null;
    setFormData({ ...formData, userType: adminUserTypeId });
  }, [userTypes, formData?.userType]);

  const handleValueChange = (event) => {
    const updatedValue = { ...formData };
    updatedValue[event?.target?.name] = event?.target?.value;
    setFormData(updatedValue);
  };

  const getOrganisations = async () => {
    setLoader(true);
    const response = await userService.getOrganisations();
    if (response.status) {
      setOrgs(response.data);
    } else {
      console.error(response?.message);
    }
    setLoader(false);
  };

  const getAdmins = async () => {
    setLoader(true);
    try {
      const response = await userService.getAdmins();
      if (response.status) {
        setAdmins(response.data);
      } else {
        console.error(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const getUserTypes = async () => {
    setLoader(true);
    const response = await userService.getUserTypes();
    if (response.status) {
      setUserTypes(response?.data);
    } else {
      console.error(response?.message);
    }
    setLoader(false);
  };

  const addAdmin = async () => {
    setLoader(true);
    try {
      const response = await userService.createAdmin(formData);
      if (response.status) {
        getAdmins();
        setFormData({});
        toasterService(toast, 'success', 'Success', response?.message, 3000);  //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const activateDeactivateOrg = async (rowData) => {
    setLoader(true);
    try {
      //const response = await userService.changeAdminUserStatus({ id: rowData._id, status: !rowData.isActive });
      const response = await userService.activateDeactivateUser({ id: rowData._id, isActive: !rowData.isActive });
      if (response.status) {
        getAdmins();
        setFormData({});
        toasterService(toast, 'success', 'Success', response?.message, 3000);  //alert(response?.message);
      } else {
        toasterService(toast, 'error', 'Error', response?.message, 3000);   //alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  }

  const tableData = {
    name: (rowData) => {
      return (
        <span className="text-capitalize">{`${rowData?.firstname || ""} ${rowData?.lastname || ""
          }`}</span>
      );
    },
   
    email: (rowData) => {
      return <>{rowData?.email || "-"}</>;
    },
    userTypeName: (rowData) => {
      return <>{rowData?.userTypeName || "-"}</>;
    },

    statusBadge: (rowData) => {
      return (
        <div className="flex align-items-center gap-2">
          {rowData.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
        </div>
      );
    },

    actions: (rowData) => {
      return (
        <>
          {rowData.isActive ?
            <i onClick={() => { activateDeactivateOrg(rowData) }} className="fa fa-times mr-2" />
            :
            <i onClick={() => { activateDeactivateOrg(rowData) }} className="fa fa-check mr-2" />
          }
        </>
      );
    },
    // org: (rowData) => {
    //   return (
    //     <span className="text-capitalize">
    //       {rowData?.organisation?.name || "-"}
    //     </span>
    //   );
    // },
  };

  return (
    <>
      {isLoader ? <Loader /> : null}
      <Toast ref={toast} />
      <ConfirmDialog />
      <div
        className="content-wrapper"
        style={{ height: "90vh", overflow: "scroll" }}
      >
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h5 className="m-0 text-dark">Manage Admin</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container-fluid">
            {/* Add Admin Form */}
            <div className="row">
              <div className="col-12">
                <div className="card mx-3 mt-3">
                  <div className="card-body row pt-4">

                    <div className="col-12 col-md-6 mt-2">
                      <span className="field">
                        <InputText
                          id="user_name"
                          name="user_name"
                          value={formData?.user_name || ""}
                          className="p-inputtext-sm col-12"
                          placeholder="Email"
                          onChange={handleValueChange}
                        />
                      </span>
                    </div>

                    <div className="col-12 col-md-6 mt-2">
                      <span className="field">
                        <InputText
                          id="firstname"
                          name="firstname"
                          value={formData?.firstname || ""}
                          className="p-inputtext-sm col-12"
                          placeholder="First Name"
                          onChange={handleValueChange}
                        />
                      </span>
                    </div>

                    <div className="col-12 col-md-6 mt-2">
                      <span className="field">
                        <InputText
                          id="lastname"
                          name="lastname"
                          value={formData?.lastname || ""}
                          className="p-inputtext-sm col-12"
                          placeholder="Last Name"
                          onChange={handleValueChange}
                        />
                      </span>
                    </div>
                    <div className="col-12 col-md-6 mt-2">
                      <span className="field">
                        <Password
                          id="password"
                          name="password"
                          value={formData?.password || ""}
                          className="p-inputtext-sm col-12 pl-0"
                          placeholder="Password"
                          toggleMask
                          onChange={handleValueChange}
                        />
                      </span>
                    </div>

                    <div className="col-12 col-md-6 mt-2">
                      <span className="field">
                        {/* <label htmlFor="email">Email</label> */}
                        <Dropdown
                          name="userType"
                          className="p-inputtext-sm col-12"
                          placeholder="Select UserType"
                          value={formData.userType}
                          options={userTypes}
                          disabled
                          onChange={handleValueChange}
                          optionLabel="name"
                          optionValue="_id"
                          filter
                          showClear
                          filterBy="name"
                          itemTemplate={(option) => (
                            <div className="text-capitalize">
                              {option?.name || ""}
                            </div>
                          )}
                          valueTemplate={(option) => {
                            return option ? (
                              <div className="text-capitalize">
                                {option?.name || ""}
                              </div>
                            ) : (
                              "Select UserType"
                            );
                          }}
                        />
                      </span>
                    </div>

                    {/* <div className="col-12 col-md-6 mt-2">
                      <span className="field">
                        <Dropdown
                          name="organisation"
                          className="p-inputtext-sm col-12"
                          placeholder="Select Organisation"
                          value={formData?.organisation}
                          options={orgs}
                          onChange={handleValueChange}
                          optionLabel="name"
                          optionValue="_id"
                          filter
                          showClear
                          filterBy="name"
                          itemTemplate={(option) => (
                            <div className="text-capitalize">
                              {option?.name || ""}
                            </div>
                          )}
                          valueTemplate={(option) => {
                            return option ? (
                              <div className="text-capitalize">
                                {option?.name || ""}
                              </div>
                            ) : (
                              "Select Organisation"
                            );
                          }}
                        />
                      </span>
                    </div> */}



                    <div className="col-12 col-md-12 mt-3">
                      <button className="btn btn-primary" onClick={addAdmin}>Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* List Admin*/}
            <div className="row">
              <div className="col-12">
                <div className="card mx-3 mt-3">
                  <DataTable
                    value={admins}
                    paginator
                    rows={10}
                    responsiveLayout="scroll"
                    filterDisplay="menu"
                    loading={isLoader}
                  >
                    <Column field="sno" header="S. No."></Column>
                    <Column
                      field="firstname"
                      body={tableData.name}
                      filter
                      filterPlaceholder="Search by name"
                      sortable
                      header="Name"
                    ></Column>
                    <Column
                      field="email"
                      sortable
                      body={tableData.email}
                      header="Email"
                    ></Column>
                    <Column
                      field="userTypeName"
                      sortable
                      body={tableData.userTypeName}
                      header="User Type"
                    ></Column>
                    {/* <Column
                      field="organisation"
                      sortable
                      sortField="organisation.name"
                      body={tableData.org}
                      header="Organisation"
                    ></Column> */}
                     <Column
                      header="Status"
                      field="status"
                      sortable
                      style={{ minWidth: '12rem' }}
                      body={tableData.statusBadge}
                      filter
                      filterPlaceholder="Search by status"
                    />
                    <Column
                      field=""
                      header="Actions"
                      body={tableData.actions}
                    ></Column>
                    
                  </DataTable>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
