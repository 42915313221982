import { URLS } from "../../constants/urls";
import { APIService } from "../api/api-service";


export const universalTemplateAccessService = {

  getFrontUserList: async () => {
    try {
      const response = await APIService.Instance.get(`${URLS.GET_FRONT_USER_LIST}`);
      return response?.data;
    } catch (err) {
      console.log("error");
      return {};
    }
  },

  getPreSelectedUserList: async () => {
    try {
      const response = await APIService.Instance.get(`${URLS.GET_PRE_SELECTED_UNIVERSAL_TEMPLATE_ACCESS}`);
      return response?.data;
    } catch (err) {
      console.log("error");
      return {};
    }
  },

  createUniversalTemplate: async (data) => {
    try {
      const response = await APIService.Instance.post(URLS.CREATE_UNIVERSAL_TEMPLATE_ACCESS, data);
      return response?.data;
    } catch (err) {
      return err.response.data;
    }
  },
};
