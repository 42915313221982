import { URLS } from "../../constants/urls";
import { APIService } from "../api/api-service";
import { getAddFeatureModel, getAddPackageModel } from "./package.model";

export const PackageService = {
    addFeature: async (data) => {
        try {
            const response = await APIService.Instance.post(URLS.ADD_FEATURE, getAddFeatureModel(data));
            return response?.data;
        } catch (err) {
            console.log("error");
            return {};
        }
    },
    getAllFeatures: async (data) => {
        try {
            const response = await APIService.Instance.get(URLS.GET_ALL_FEATURES ,data);
            return response?.data;
        } catch (err) {
            console.log("error");
            return {};
        }
    },
    addPackage: async (data) => {
        try {
            const response = await APIService.Instance.post(URLS.ADD_PACKAGE, getAddPackageModel(data));
            return response?.data;
        } catch (err) {
            console.log("error");
            return {};
        }
    },
    updatePackage: async (data) => {
        try {
            const response = await APIService.Instance.put(`${URLS.ADD_PACKAGE}/${data?._id}`, getAddPackageModel(data));
            return response;
        } catch (err) {
            console.log("error");
            return {};
        }
    },
    getPackages: async (data={}) => {
        try {
            const response = await APIService.Instance.get(URLS.GET_PACKAGES,data);
            return response?.data;
        } catch (err) {
            return {};
        }
    },
    getPackageById: async (packageId) => {
        try {
            const response = await APIService.Instance.get(`${URLS.GET_PACKAGES}?packageId=${packageId}`);
            return response?.data;
        } catch (err) {
            return {};
        }
    }
}