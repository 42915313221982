import { Component } from "react";
import React from "react";
import { withRouter } from "react-router";


import Headers from "../master/Header";
import { Menu } from "../master/Menu";
import Footer from "../master/Footer";

class UserBoards extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      item: null,
      items: [],
      isLoading: true,
      error: null,
    };
  }
  
  componentDidMount() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId: this.props.match.params.id }),
    };
    fetch("/users/get-boards", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        // console.warn("veer call to next functions" + json.message);
        this.setState({
          isLoading: false,
          items: json.data,
          // item: json.data[0]
        });
        // <this.getAllBoards />;
      })
      .catch((error) =>
        this.setState({ error: error.message, isLoading: false })
      );
  }


  getMeetingDetails = (props) => {
    // this.forceUpdate()
    this.setState(this.state);
    // alert(props.boardItem._id);
    // console.log(props);
    // console.warn("veer call to next functions" + props.status);
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ meetingId: props.id, status: props.status }),
    // };

    // fetch("users/get-board", requestOptions)
    //   .then((res) => res.json())
    //   .then((json) => {
    //     console.warn("veer get-board" + json.message);
    //     console.log(json);
    //     this.setState({
    //       isLoading: false,
    //       item: json.data,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     this.setState({ error: error.message, isLoading: false });
    //   });
  };

  render() {
    const { item, items } = this.state;
    
    return (
      <div>
        <Headers />
        <Menu />
        
        <div className="content-wrapper">
        
          <div className="row">

            {/* left card */}
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">Meeting List </h3>
                </div>
                <div className="card-body">
                  <table
                    id="example2"
                    className="table table-bordered table-hover datatable"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item) => (
                        <tr>
                          <td>

                          <span
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    color :"#1472f3"
                                  }}
                                  onClick={(e) => {
                                    this.getMeetingDetails({
                                      id: item._id,
                                      boardItem: item
                                    });
                                  }}
                                >{item.eventName}</span>

                          {/* <Link to={`/user-boards/${item._id}`} >{item.eventName}</Link> */}
                          </td>
                          <td>{item.eventDescription}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* right card */}
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">Meeting Details</h3>
                </div>
                <div className="card-body">
                  
                <table id="example2"
                    className="table datatable" >
                    {item!=null ?
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>{item.eventName}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>{item.eventDescription}</td>
                      </tr>
                      <tr>
                        <td>Is Protected</td>
                        <td>{item.isProtected ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Is Locked</td>
                        <td>{item.isLocked ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>{item.eventDate}</td>
                      </tr>
                      <tr>
                        <td>Initiator</td>
                        <td>{item.initatorUsername}</td>
                      </tr>
                      <tr>
                        <td>Meeting ID</td>
                        <td>{item.meetingId}</td>
                      </tr>
                    </tbody>
                    : 'No meeting selected'
                  }
                                        
                  </table>

                </div>
              </div>
            </div>

          </div>
        </div>
        
        <Footer />
      </div>
    );
  }
}


export default withRouter(UserBoards)