import { useEffect, useState } from "react";
import React from "react";

import Headers from "../master/Header";
import { Menu } from "../master/Menu";
import Footer from "../master/Footer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PackageService } from "../../services/package/package.service";

export const Features = () => {
  const [formData, setFormData] = useState({});
  const [features, setFeatures] = useState([]);

  // for table
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    limit: 10,
    page: 1,
  });

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      getAllFeatures();
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    console.log("eventtt", event);
    setLazyParams({ limit: event.rows, page: event.page + 1 });
  };
  // for table

  // useEffect(() => {
  //   getAllFeatures();
  // }, []);

  const handleValueChange = (event) => {
    const updatedValue = { ...formData };
    updatedValue[event?.target?.name] = event?.target?.value;
    setFormData(updatedValue);
  };

  const getAllFeatures = async () => {
    const response = await PackageService.getAllFeatures({
      params: lazyParams,
    });
    if (response.status) {
      setLoading(false);
      setFeatures(response?.data?.features);
      setTotalRecords(response?.data?.totalFeatures);
    }
  };

  const addFeature = async () => {
    const response = await PackageService.addFeature(formData);
    if (response?.status) {
      getAllFeatures();
      // console.log(formData);
      setFormData({ name: "" });
    }
  };

  const tableData = {
    status: (rowData) => {
      return <>{rowData && (rowData.isActive ? "Yes" : "No")}</>;
    },
    serialNo: (rowData, props) => {
      return (
        <>
          {rowData &&
            lazyParams.limit * (lazyParams.page - 1) + props.rowIndex + 1}
        </>
      );
    },
  };

  return (
    <>
      {/* {isLoader ? <Loader /> : null} */}
      <div>
        <Headers />
        <Menu />
        <div className="content-wrapper">
          <div></div>
          <div className="row">
            <div className="col-12">
              <div className="card mx-3 mt-3">
                <div className="card-header">
                  <h3 className="card-title"> Add Features</h3>
                </div>
                <div className="card-body d-flex ">
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Feature Name"
                    onChange={handleValueChange}
                  />
                  <button
                    className="btn btn-primary ml-lg-3"
                    onClick={addFeature}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Feature Details</h3>
                </div>
                <div className="card-body">
                  {features && features?.length > 0 && (
                    <DataTable
                      lazy
                      paginator
                      first={lazyParams.first}
                      rows={lazyParams.limit}
                      totalRecords={totalRecords}
                      onPage={onPage}
                      loading={loading}
                      value={features}
                      responsiveLayout="scroll"
                    >
                      <Column header="S.No." body={tableData.serialNo}></Column>
                      <Column field="name" header="Name"></Column>
                      <Column header="Active" body={tableData.status}></Column>
                    </DataTable>
                  )}
                  {/* <table
                  id="example2"
                  className="table table-bordered table-hover"
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {features.map((feature, index) => (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{feature?.name}</td>
                          <td>{feature?.isActive ? "True" : "False"}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
