import React from "react";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { userService } from "../../services/users/users.service";
import { Loader } from "../loader/Loader";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import "./user.css";
import { AuthService } from "../../services/auth/auth.service";
import { USER_TYPE } from "../../constants/constants";
import { Dialog } from 'primereact/dialog';


export const ManageUsers = () => {
  const [isLoader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [currentUserType, setCurrentUserType] = useState(() => AuthService.getUserType());

  const [user, setUser] = useState(() => AuthService.getUserDetails());

  const [orgs, setOrgs] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [displayForm, setDisplayForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [lazyParams, setLazyParams] = useState({
    limit: 10,
    page: 1,
  });
  const [rowData, setRowData] = useState({})


  useEffect(() => {
    getOrganisations();
    getUserTypes();
    getAllUsers();
  }, []);


  const handleValueChange = (event) => {
    // console.log('formssss',event.target.value)
    const updatedValue = { ...formData };
    updatedValue[event?.target?.name] = event?.target?.value;
    setFormData(updatedValue);
  };

  const getOrganisations = async () => {
    setLoader(true);
    const response = await userService.getOrganisations();
    if (response && response?.status) {
      setOrgs(response.data);
    } else {
      // console.error(response?.message);
    }
    setLoader(false);
  };

  const getUserTypes = async () => {
    setLoader(true);
    const response = await userService.getUserTypes();
    if (response && response?.status) {
      setUserTypes(response?.data);
    } else {
      console.error(response?.message);
    }
    setLoader(false);
  };

  const getAllUsers = async () => {
    setLoader(true);
    try {
      const response = await userService.getAllUsers();
      if (response && response?.status) {
        setUsers(response.data);
      } else {
        console.error(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };



  const addUser = async () => {
    setLoader(true);
    try {
      const response = await userService.createUser(formData);
      if (response.status) {
        setFormData({});
        setDisplayForm(false)
        getAllUsers();
        alert(response?.message);
      } else {
        alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const updateUser = async () => {
    setLoader(true);
    try {
      const reqObj = {
        id: formData._id,
        email: formData.email,
        firstname: formData.firstname,
        lastname: formData.lastname,
        displayName: formData.displayName,
        userType: formData.userType,
        organisation: formData.organisation
      }
      const response = await userService.updateUser(reqObj);
      if (response.status) {
        setFormData({});
        setDisplayForm(false)
        setRowData({})
        setIsEdit(false)
        getAllUsers();
        alert(response?.message);
      } else {
        alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const onDeleteUser = async (rowData) => {
    setLoader(true);
    try {
      const response = await userService.deleteUser(rowData._id);
      if (response.status) {
        getAllUsers();
        alert(response?.message);
      } else {
        alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  };

  const onEditClick = (rowData) => {
    rowData.userType = rowData?.userType?._id && userTypes?.find(item => item?._id == rowData?.userType?._id)?._id || null;
    rowData.organisation = rowData?.organisation?._id && orgs?.find(item => item?._id == rowData?.organisation?._id)?._id || null;
    setRowData(rowData);
    setIsEdit(true)
    setFormData(rowData);
    setDisplayForm(rowData)
  }

  const viewUserDetails = async (rowData) => {
    setShowUserDetails(true);
    setLoader(true);
    try {
      const response = await userService.userDetails({ id: rowData._id });
      if (response.status) {
        setUserDetails(response.data);
      } else {
        alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  }

  const activateDeactivateUser = async (rowData) => {
    setLoader(true);
    try {
      const response = await userService.activateDeactivateUser({ id: rowData._id, status: !rowData.isActive });
      if (response.status) {
        getAllUsers();
        alert(response?.message);
      } else {
        alert(response?.message);
      }
    } finally {
      setLoader(false);
    }
  }

  const tableData = {
    name: (rowData) => {
      return (
        <span className="text-capitalize">{`${rowData?.firstname || ""} ${rowData?.lastname || ""
          }`}</span>
      );
    },
    userType: (rowData) => {
      return (
        <span className="text-capitalize">{`${rowData?.userType?.name || ""
          }`}</span>
      );
    },
    status: (rowData) => {
      return <>{rowData && (rowData.isActive ? "Active" : "Inactive")}</>;
    },
    statusBadge: (rowData) => {
      return (
        <div className="flex align-items-center gap-2">
          {rowData.isActive ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}
        </div>
      );
    },

    email: (rowData) => {
      return <>{rowData?.user_name || "-"}</>;
    },
    org: (rowData) => {
      return (
        <span className="text-capitalize">
          {rowData?.organisation?.name || "-"}
        </span>
      );
    },
    editUser: (rowData) => {
      return (
        <>
          <i onClick={() => { onEditClick(rowData) }} className="fa fa-edit " />
        </>
      );
    },
    deleteUser: (rowData) => {
      return (
        <>
          <i onClick={() => { onDeleteUser(rowData) }} className="fa fa-trash" />
        </>
      );
    },
    viewUserDetails: (rowData) => {
      return (
        <>
          <i onClick={() => { viewUserDetails(rowData) }} className="fa fa-eye" />
        </>
      );
    },
    updateStatus: (rowData) => {
      return <>
        {rowData.isActive ?
          <i onClick={() => { activateDeactivateUser(rowData) }} className="fa fa-times" />
          :
          <i onClick={() => { activateDeactivateUser(rowData) }} className="fa fa-check" />
        }
      </>
    }
  };



  return (
    <>
      {isLoader ? <Loader /> : null}
      <div
        className="content-wrapper"
        style={{ height: "90vh", overflow: "scroll" }}
      >
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <h5 className="m-0 text-dark">Users</h5>
                <button className="btn btn-primary" onClick={() => setDisplayForm(true)} style={{ float: 'right' }}>
                  Add Users
                </button>
              </div>

            </div>
          </div>
        </div>

        <div className="content">
          <div className="container-fluid">

            {/* /.row */}

            <div className="row">
              {/* <div className="col-12 col-md-12 mt-2">
                   
              </div> */}
              <div className="col-12">
                <div className="card mx-0 mt-3">
                  <DataTable
                    value={users}
                    paginator
                    rows={10}
                    responsiveLayout="scroll"
                    loading={isLoader}
                  >
                    <Column
                      field="sno"
                      sortable
                      header="S.No."
                    ></Column>
                    <Column
                      field="firstname"
                      sortable
                      filter
                      body={tableData.name}
                      header="Name"
                    ></Column>
                    <Column
                      field="email"
                      sortable
                      body={tableData.email}
                      header="Email"
                    ></Column>
                    <Column
                      field="organisation"
                      sortable
                      sortField="organisation.name"
                      body={tableData.org}
                      header="Organisation"
                    ></Column>
                    <Column
                      field="userType"
                      sortable
                      sortField="userType.name"
                      filter
                      filterField="userType.name"
                      body={tableData.userType}
                      header="User Type"
                    ></Column>
                    {/* <Column
                      field="isActive"
                      body={tableData.status}
                      header="Active"
                    ></Column> */}
                    <Column
                      header="Status"
                      field="isActive"
                      style={{ minWidth: '12rem' }}
                      body={tableData.statusBadge}
                      filter
                      filterPlaceholder="Search by country"
                    />
                    <Column
                      field=""
                      body={tableData.editUser}
                    ></Column>
                    <Column
                      field=""
                      body={tableData.deleteUser}
                    ></Column>
                    <Column
                      field=""
                      body={tableData.viewUserDetails}
                    ></Column>
                    <Column
                      field=""
                      body={tableData.updateStatus}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </div>
      </div>


      {/* popup */}
      <Dialog header={isEdit ? 'Edit User' : 'Add User'} visible={displayForm} style={{ width: '50vw' }} onHide={() => { setDisplayForm(false); setRowData({}); setIsEdit(false); setFormData({}) }}>
        <div className="row m-0">
          <div className="col-12" style={{ padding: '5px 5px' }}>
            <div className="card mt-0 mb-3">
              <div className="card-body row m-0 p-2">
                {/* <div className="col-12 col-md-12"> */}
                <div className="col-4 col-md-6 my-2">
                  <span className="field">
                    {/* <label htmlFor="email">Email</label> */}
                    <InputText
                      id="email"
                      name="email"
                      value={formData?.email || ""}
                      className="p-inputtext-sm col-12"
                      placeholder="Email"
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                {/* </div> */}

                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <InputText
                      id="firstname"
                      name="firstname"
                      value={formData?.firstname || ""}
                      className="p-inputtext-sm col-12"
                      placeholder="First Name"
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <InputText
                      id="lastname"
                      name="lastname"
                      value={formData?.lastname || ""}
                      className="p-inputtext-sm col-12"
                      placeholder="Last Name"
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    {/* <label htmlFor="email">Email</label> */}
                    <Dropdown
                      name="organisation"
                      className="p-inputtext-sm col-12"
                      placeholder="Select Organisation"
                      value={formData?.organisation}
                      options={orgs}
                      disabled={user?.userType?.name === USER_TYPE.ADMIN}
                      onChange={handleValueChange}
                      optionLabel="name"
                      optionValue="_id"
                      filter
                      showClear
                      filterBy="name"
                      itemTemplate={(option) => (
                        <div className="text-capitalize">
                          {option?.name || ""}
                        </div>
                      )}
                      valueTemplate={(option) => {
                        return option ? (
                          <div className="text-capitalize">
                            {option?.name || ""}
                          </div>
                        ) : (
                          "Select Organisation"
                        );
                      }}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    {/* <label htmlFor="email">Email</label> */}
                    <Dropdown
                      name="userType"
                      className="p-inputtext-sm col-12"
                      placeholder="Select UserType"
                      value={formData.userType}
                      options={userTypes}
                      // disabled
                      onChange={handleValueChange}
                      optionLabel="name"
                      optionValue="_id"
                      filter
                      showClear
                      filterBy="name"
                      itemTemplate={(option) => (
                        <div className="text-capitalize">
                          {option?.name || ""}
                        </div>
                      )}
                      valueTemplate={(option) => {
                        return option ? (
                          <div className="text-capitalize">
                            {option?.name || ""}
                          </div>
                        ) : (
                          "Select UserType"
                        );
                      }}
                    />
                  </span>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <span className="field">
                    <Password
                      id="password"
                      name="password"
                      value={formData?.password || ""}
                      className="p-inputtext-sm col-12 pl-0"
                      placeholder="Password"
                      toggleMask
                      onChange={handleValueChange}
                    />
                  </span>
                </div>
                {/* {JSON.stringify(formData)} */}

              </div>

            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 mt-2" >
          <button className="btn btn-primary" onClick={isEdit ? updateUser : addUser} style={{ float: 'right' }}>
            Submit
          </button>
        </div>
      </Dialog>

      {/* user Details Popup */}
      <Dialog header="User Info" visible={showUserDetails} style={{ width: '50vw' }} onHide={() => { setShowUserDetails(false) }}>
        <div className="row m-0">
          <div className="col-12" style={{ padding: '5px 5px' }}>
            <div className="card mt-0 mb-3">
              <div className="card-body row m-0 p-2">
                <div className="col-12 col-md-12 my-2">
                  Email: {userDetails?.email}
                </div>
                <div className="col-12 col-md-6 my-2">
                  First Name: {userDetails?.firstname}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Last Name: {userDetails?.lastname}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Last Name: {userDetails?.lastname}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Organisation: {orgs?.find(item => item?._id == userDetails?.organisation?._id)?.name || 'N/A'}
                </div>
                <div className="col-12 col-md-6 my-2">
                  User Type: {userTypes?.find(item => item?._id == userDetails?.userType?._id)?.name || 'N/A'}
                </div>
                <div className="col-12 col-md-6 my-2">
                  Status: {userDetails?.isActive ? 'Active' : 'Inactive'}
                </div>

              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* user Details Popup */}

    </>
  );
};
